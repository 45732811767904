<!-- 地区级联 -->
<template>
  <!-- <div> -->
  <el-cascader
    ref="cascaderAddr"
    :placeholder="placeholder || '请选择住址'"
    v-model="myValue"
    @change="handleChange"
    :props="handleprops"
    :options="handoptions"
  >
  </el-cascader>
  <!-- </div> -->
</template>
<script>
import { reactive, toRefs, ref, watch } from 'vue'
import service from '@/utils/request'
export default {
  name: 'selectCascader',
  props: ['value', 'placeholder'],
  emits: ['input'],
  setup(props, ctx) {
    const state = reactive({
      myValue: [],
      handoptions: [],
      cascaderAddr: null,
    })

    watch(props.value, (val) => {
      state.myValue = val
      if (state.cascaderAddr) {
        state.cascaderAddr.dropDownVisible = false // 监听值发生变化就关闭它
      }
    })

    let handleprops = ref('')
    handleprops = {
      lazy: true,
      value: 'id',
      label: 'name',
      async lazyLoad(node, resolve) {
        const { value, level } = node
        let nodes = []
        let param = {
          pid: value,
        }
        let res = await service.post('/api/location/get_list', param)
        if (res.code === 0) {
          nodes = res.data.location_list
          nodes.map(function (item) {
            item.leaf = level >= 1
          })
        }
        resolve(nodes)
      },
    }

    const valInt = async (row) => {
      state.myValue = row
    }

    const handleChange = (val) => {
      ctx.emit('input', val)
    }

    return {
      ...toRefs(state),
      handleprops,
      handleChange,
      valInt,
    }
  },
}
</script>

<style lang="scss" scoped></style>
