/**
 * @Author: hjp
 * @Desc 自定义校验器规则
 */
export default {
  phone(rule, value, callback) {
    let reg = /^1\d{10}$/
    if (!reg.test(value)) {
      callback(new Error())
    } else {
      callback()
    }
  },
}
